<template>
  <div>
    <div class="custom-container p-3">
      <h3>
        <b>Categorización</b>
      </h3>
      <hr />
      <div class="row">
        <div class=" col-12 col-lg-3">
          <div class="row bg-dark   p-2 rounded" style="gap: 0.5rem; height: 23rem; margin-right:0px;">
            <div class="col-12">
              <label for="isTransporte">¿Es una empresa de transporte?</label><br />
              <b-form-select id="isTransporte" v-model="categorizacion.isTransporte" value-field="value"
                text-field="text" :options="optionsRes">
                <template #first>
                  <b-form-select-option selected :value="''" disabled>
                    -- selecciona una opción --
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="col-12">
              <label for="totalConductores">Total de conductores</label><br />
              <b-form-input id="totalConductores" type="number" v-model="categorizacion.totalConductores"
                :disabled="categorizacion.isTransporte === ''"></b-form-input>
            </div>
            <div class="col-12">
              <label for="totalVehiculos">Total de vehículos</label><br />
              <b-form-input id="totalVehiculos" type="number" v-model="categorizacion.totalVehiculos"
                :disabled="categorizacion.isTransporte === ''"></b-form-input>
            </div>
            <div class="col-12 d-flex">
              <vs-button block :loading="isLoading" @click="handlerSubmit" :disabled="isDisabled">
                Aceptar y
                continuar
              </vs-button>

            </div>
          </div>
        </div>
        <div class="col-12 col-lg mt-2   mt-lg-0">
          <div class="row w-100   " style="gap: 0.5rem">
            <div class="col-12 text-center h5">
              Resumen grupos PESV en función de la misionalidad y tamaño de la
              organización
            </div>
            <div class="col-12">
              <div class="row" style="gap: 0.5rem">
                <div class="col-4 bg-dark-pesv p-3 text-center font-weight-bold">
                  NIVEL DE DISEÑO E IMPLEMENTACIÓN DEL PESV
                </div>
                <div class="col">
                  <div class="row" style="gap: 0.5rem">
                    <!-- <div class="col-12 bg-dark-pesv p-3 text-center font-weight-bold">
                      MISIONALIDAD DE LA ORGANIZACIÓN
                    </div>
                    <vs-alert
                      :color="categorizacion.isTransporte === 2 ? 'success' : 'primary'"
                      class="p-3"
                    >
                      <template #title>
                        1. Empresas dedicadas a la prestación del servicio de Transporte Terrestre Automotor
                      </template>
                    </vs-alert>

                    <vs-alert
                      :color="categorizacion.isTransporte === 7 ? 'success' : 'primary'"
                      class="p-3"
                    >
                      <template #title>
                        2. Organizaciones dedicadas a actividad diferente al Transporte
                      </template>
                    </vs-alert> -->
                    <div class="col-12 bg-dark-pesv p-3 text-center font-weight-bold">
                      MISIONALIDAD DE LA ORGANIZACIÓN
                    </div>
                    <div :class="`col-12 col-lg ${categorizacion.isTransporte === 2
                      ? 'bg-primary-pesv'
                      : 'bg-dark-pesv'
                      } p-3`">
                      1 . Empresas dedicadas a la prestación del servicio de
                      Transporte Terrestre Automotor
                    </div>
                    <div :class="`col-12 col-lg ${categorizacion.isTransporte === 7
                      ? 'bg-primary-pesv'
                      : 'bg-dark-pesv'
                      } p-3`">
                      2 . Organizaciones dedicadas a actividad diferente al
                      Transporte
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row" style="gap: 0.5rem">
                <div class="col-12 col-lg-4">
                  <div class="row" style="gap: 0.5rem">
                    <div class="col-3 bg-dark-pesv vertical-text font-weight-bold">
                      TAMAÑO DE LA
                      <br />
                      ORGANIZACIÓN
                    </div>
                    <div class="col">
                      <div class="row" style="gap: 0.5rem">
                        <div :class="`col-12 ${levelCompany === LEVELS.BASICO ? 'bg-primary-pesv' : 'bg-dark-pesv'
                          } size-box`">
                          1 . Básico
                        </div>
                        <div :class="`col-12 ${levelCompany === LEVELS.ESTANDAR
                          ? 'bg-primary-pesv'
                          : 'bg-dark-pesv'
                          } size-box`">
                          2 . Estándar
                        </div>
                        <div :class="`col-12 ${levelCompany === LEVELS.AVANZADO
                          ? 'bg-primary-pesv'
                          : 'bg-dark-pesv'
                          } size-box`">
                          3 . Avanzado
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg">
                  <div class="row mb-2" style="gap: 0.5rem">
                    <div class="col-12 col-lg size-box d-flex flex-column">
                      <span>
                        <small class="text-primary d-lg-none mr-2">Misionalidad : 1</small>
                        <small class="text-primary d-lg-none">Nivel : Básico</small>
                      </span>
                      Entre 11 y 19 vehículos o entre 2 y 19 conductores,
                    </div>
                    <div class="col-12 col-lg size-box d-flex flex-column">
                      <span>
                        <small class="text-primary d-lg-none mr-2">Misionalidad : 2</small>
                        <small class="text-primary d-lg-none">Nivel : Básico</small>
                      </span>
                      Entre 11 y 49 vehículos o entre 2 y 49 conductores,
                    </div>
                  </div>
                  <div class="row mb-2" style="gap: 0.5rem">
                    <div class="col-12 col-lg size-box d-flex flex-column">
                      <span>
                        <small class="text-primary d-lg-none mr-2">Misionalidad : 1</small>
                        <small class="text-primary d-lg-none">Nivel : Estándar</small>
                      </span>
                      Entre 20 y 50 vehículos o entre 20 y 50 conductores,
                    </div>
                    <div class="col-12 col-lg size-box d-flex flex-column">
                      <span>
                        <small class="text-primary d-lg-none mr-2">Misionalidad : 2</small>
                        <small class="text-primary d-lg-none">Nivel : Estándar</small>
                      </span>
                      Entre 50 y 100 vehículos o entre 50 y 100 conductores,
                    </div>
                  </div>
                  <div class="row" style="gap: 0.5rem">
                    <div class="col-12 col-lg size-box d-flex flex-column">
                      <span>
                        <small class="text-primary d-lg-none mr-2">Misionalidad : 1</small>
                        <small class="text-primary d-lg-none">Nivel : Avanzado</small>
                      </span>
                      Más de 50 vehículos o más de 50 conductores,
                    </div>
                    <div class="col-12 col-lg size-box d-flex flex-column">
                      <span>
                        <small class="text-primary d-lg-none mr-2">Misionalidad : 2</small>
                        <small class="text-primary d-lg-none">Nivel : Avanzado</small>
                      </span>
                      Más de 100 vehículos o más de 100 conductores,
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
const Swal = require("sweetalert2");
export default {
  data() {
    return {
      userLogged: null,
      LEVELS: {
        BASICO: 'Básico',
        ESTANDAR: 'Estándar',
        AVANZADO: 'Avanzado',
      },
      categorizacion: {
        isTransporte: '',
        totalConductores: 0,
        totalVehiculos: 0,
      },
      isLoading: false,
      optionsRes: [
        { value: 2, text: "Si" },
        { value: 7, text: "No" },
      ],
    };
  },
  mounted() {
    this.initializeData();
  },
  computed: {
    ...mapState("pesv", ["TOKEN_SISI", "credential"]),
    isDisabled() {
      const { isTransporte, totalConductores, totalVehiculos } = this.categorizacion
      return !(isTransporte && totalConductores > 0 && totalVehiculos > 0);
    },
    levelCompany() {
      const { isTransporte, totalConductores, totalVehiculos } = this.categorizacion;

      const determineLevel = (vehiculos, conductores, basicoVehiculos, basicoConductores, estandarVehiculos, estandarConductores) => {
        if ((vehiculos >= basicoVehiculos.min && vehiculos <= basicoVehiculos.max) ||
          (conductores >= basicoConductores.min && conductores <= basicoConductores.max)) {
          return this.LEVELS.BASICO;
        }

        if ((vehiculos >= estandarVehiculos.min && vehiculos <= estandarVehiculos.max) ||
          (conductores >= estandarConductores.min && conductores <= estandarConductores.max)) {
          return this.LEVELS.ESTANDAR;
        }

        if (vehiculos > estandarVehiculos.max || conductores > estandarConductores.max) {
          return this.LEVELS.AVANZADO;
        }

        return null;
      };

      const config = isTransporte === 2
        ? {
          basicoVehiculos: { min: 11, max: 19 },
          basicoConductores: { min: 2, max: 19 },
          estandarVehiculos: { min: 20, max: 50 },
          estandarConductores: { min: 20, max: 50 },
        }
        : {
          basicoVehiculos: { min: 11, max: 49 },
          basicoConductores: { min: 2, max: 49 },
          estandarVehiculos: { min: 50, max: 100 },
          estandarConductores: { min: 50, max: 100 },
        };

      return determineLevel(totalVehiculos, totalConductores, config.basicoVehiculos, config.basicoConductores, config.estandarVehiculos, config.estandarConductores);
    }

  },
  methods: {
    ...mapActions("pesv", ["useRequestSisi"]),
    async initializeData() {
      // obtener usuario activo
      this.userLogged = JSON.parse(localStorage.getItem("setPersonaAct"));

      // obtener categorización guardada en helexium
      const dataCategorizacion = await this.$store.dispatch("hl_get", {
        path: "PesvPasosEvaluacion/GetCategorizacionByEmpresa/" + this.userLogged.empresa.id,
      });

      const { success, message, data } = dataCategorizacion;

      if (!success) {
        Swal.fire(
          "Error!",
          message,
          "error"
        );
        return
      }

      if (data) {
        const { isEmpresaDeTransporte, totalConductores, totalVehiculos } = data;
        this.categorizacion.isTransporte = isEmpresaDeTransporte ? 2 : 7;
        this.categorizacion.totalConductores = totalConductores;
        this.categorizacion.totalVehiculos = totalVehiculos;
      }


    },
    async handlerSubmit() {
      this.isLoading = true;
      // Verificar si existe autenticación de Sisi
      const isSisiAuth = localStorage.getItem("sisiAuth");

      if (!isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        )
        this.isLoading = false;
        return
      }

      const { isTransporte, totalConductores, totalVehiculos } = this.categorizacion;

      // Obtener datos de categorización con Sisi
      const categorizacionResponse = await this.useRequestSisi({
        method: "GET",
        path: "modalidad/filtro",
        params: {
          idVigilado: this.credential.usuario,
        },
      });

      if (!categorizacionResponse.ok) {
        Swal.fire(
          "Error!",
          "No se pudo obtener la categorización con Sisi",
          "error"
        )
        this.isLoading = false;
        return
      }

      const modalidadesRadioEliminar = () => {
        const { filas } = categorizacionResponse.data.modalidadRadio;

        if (!filas) return [];

        const modalidades = filas
          .filter(item =>
            (isTransporte === 2 && item.modalidad.includes('enseñanza')) ||
            (isTransporte === 7 && item.modalidad.includes('transporte'))
          )
          .map(item => item.id);

        return modalidades;
      };

      const body = {
        idVigilado: this.credential.usuario,
        token: this.TOKEN_SISI,
        datos: [],
        modalidadesRadio: [
          {
            idModalidad: isTransporte,
            idRadio: 2
          }
        ],
        modalidadesRadioEliminar: modalidadesRadioEliminar(),
        totales: {
          conductores: parseInt(totalConductores),
          vehiculos: parseInt(totalVehiculos),
        }
      };

      // Guardar datos de categorización
      const saveCategorizacionResponse = await this.useRequestSisi({
        method: "POST",
        path: "modalidad/guardar",
        body
      });

      let { ok, data } = saveCategorizacionResponse;

      let nivel = data.nombre;

      if (this.levelCompany !== data.nombre) {
        const res = await this.useRequestSisi({
          method: "POST",
          path: "modalidad/guardar",
          body
        });

        nivel = res.data.nombre;
        ok = res.ok;
        data = res.data;
      }

      // guardar datos de categorización en helexium
      const saveCategorizacion = await this.$store.dispatch("hl_post", {
        path: "PesvPasosEvaluacion/CreateCategorizacion/",
        data: {
          empresaId: this.userLogged.empresa.id,
          isEmpresaDeTransporte: isTransporte == 2 ? true : false,
          totalConductores: parseInt(totalConductores),
          totalVehiculos: parseInt(totalVehiculos),
          nivel
        }
      });

      if (!ok && !saveCategorizacion) {
        Swal.fire(
          "Error!",
          "No se pudo guardar la categorización",
          "error"
        )
        this.isLoading = false;
        return
      }

      Swal.fire(
        "Listo!",
        "Se ha guardado correctamente la categorización",
        "success"
      );
      this.isLoading = false;

      this.$router.push("/helex/pesv/");
    },
  },

};
</script>

<style scoped>
.bg-primary-pesv{
  background-color: #2aaffc46;
  color: #2aaffc;
  border-radius:  15px;
  text-wrap: balance;
}
.bg-dark-pesv{
  background-color: #6272ff28;
  color: #707eff;
  border-radius:  15px;
  text-wrap: balance;
  
}
.size-box {
  padding: 0.8rem;
  height: 4rem;
}

.vertical-text {
  text-align: center;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
</style>
